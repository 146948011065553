<style scoped>
.section-event-category.performer-category-wrap{
  background-color: rgb(246 247 251);
  padding: 40px 0;
}
.performer-listing-wrap{
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
  background-color: #fff;
  padding: 30px 30px 20px;
  border-radius: 10px;
  box-shadow: 0px 15px 50px rgb(0 0 0 / 7%);
}
.performer-nearest-evnets{
  margin-bottom: 30px;
}
.performer-nearest-evnets.mobile-dis-none{
  display: none;
}
.performer-title-wrapper{
  padding: 0 0px 20px;
  margin: 0 0 30px;
  border-bottom: 1px solid #ccc;
  width: 100%;
}
.performer-title-wrapper h2{
  color: #04092c;
  margin: 0;
  font-family: 'Heebo', sans-serif!important;
  font-weight: 600;
  font-size: 24px;
}
.performer-listing-wrap a{
  padding:10px 15px;
  margin:0 0 15px;
  border-radius:10px;
  background-color: #fff;
  /* background-color: rgb(245 161 0/6%); */
  position: relative;
  overflow: hidden;
  transition:all .4s ease-in-out;
  -webkit-transition:all .4s ease-in-out;
  -moz-transition:all .4s ease-in-out;
  -o-transition:all .4s ease-in-out;
  -ms-transition:all .4s ease-in-out;
  cursor:pointer;
  width: 100%;
  border:1px solid #eee;
}
.performer-listing-wrap a:hover,
.performer-listing-wrap a:focus{
  /* box-shadow: 0 0 10px 0 rgb(195 195 195 / 70%); */
  /*box-shadow: rgb(36 63 97 / 11%) 0px 2px 12px, rgb(36 63 97 / 12%) 0px 0.5px 2px;*/
  background-color: #f4f4f4;
}
.performer-listing-wrap .performers-list-item{
  display:flex;
  justify-content:space-between;
  flex-direction:row;
  width:100%;
  align-items:center
}
.performer-list-name{
  width:calc(100% - 140px)
}
.performer-listing-wrap a .performer-name{
  font-size:16px;
  line-height:22px;
  font-weight:700;
  letter-spacing:.4px;
  width:100%;
  color:#04092c;
  display:flex;
  align-items:center;
  justify-content:flex-start;
  word-break:break-word;
  margin-bottom:10px
}
.performer-listing-wrap a .category-name{
  color:#929292;
  font-size:13px;
  line-height:20px
}
.performer-listing-wrap a .category-label{
  font-weight:600;
  color:#f08100
}
.innerpage-banner{
  height:150px;
  position: relative;
  background-image:url('~@/../public/images/share-action.webp');
}
.innerpage-banner:after{
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: 0 0;
  background-color: rgba(0,0,0,.5);
}
.innerpage-banner img{
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.innerpage-banner .section-header{
  -webkit-box-align:center;
  -ms-flex-align:center;
  align-items:center;
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -ms-flex:1;
  flex:1;
  height:100%;
  max-width:100%;
  position:absolute;
  width:100%;
  z-index:3;
  justify-content:center;
  top: 50%;
  transform: translateY(-50%);
  padding: 0 10px;
}
.innerpage-banner .section-header h1{
  color:#fff;
  font-family:'Heebo', sans-serif !important;
  font-weight:700;
  font-size: 36px;
  text-align: center;
  margin-bottom: 8px;
}
.performer-header{
  flex-direction:column
}
.performer-header h2{
  margin-bottom:20px;
}
.search-ticket-btn{
  background:0 0;
  border:0;
  background-color: rgb(240 129 0/70%);
  color:#fff;
  padding: 8px 12px;
  margin-left:auto;
  font-family:'Heebo', sans-serif;
  font-weight:600;
  display:block;
  border-radius:6px;
  font-size:15px;
  line-height:normal;
  -moz-transition:all .3s ease-in-out 0s;
  -webkit-transition:all .3s ease-in-out 0s;
  -ms-transition:all .3s ease-in-out 0s;
  -o-transition:all .3s ease-in-out 0s;
  transition:all .3s ease-in-out 0s
}
.search-ticket-btn:hover{
  background-color: #f08100;
}
.show-more-btn{
  background-color:rgb(240 129 0/20%);
  color:#f08100;
  border-radius:6px;
  padding: 14px 28px;
  font-family:'Heebo', sans-serif;
  font-weight:600;
  display:block;
  font-size:16px;
  line-height:normal;
  -moz-transition:all .3s ease-in-out 0s;
  -webkit-transition:all .3s ease-in-out 0s;
  -ms-transition:all .3s ease-in-out 0s;
  -o-transition:all .3s ease-in-out 0s;
  transition:all .3s ease-in-out 0s;
  border:0
}
.show-more-btn:hover{
  background-color:rgb(240 129 0);
  color:#fff
}
.load-more-spinner{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.load-more-spinner i{
  color:#f08100;
  font-size:30px
}
.dis-none{
  display:none!important
}
.no-records-found{
  font-size: 24px;
  line-height: 28px;
  font-weight: 600;
  text-align: center;
  margin: 0 0 0px 0;
  display: flex;
  align-items: center;
}
.performer-slider-section{
  padding: 3% 16%;
}
.performer-slider-wrapper{
  position: relative;
  min-height: 110px;
}
.performer-slider-wrapper .section-header{
  margin-bottom: 25px;
}
.performer-slider-wrapper .section-header h2{
  margin-bottom: 0px;
  font-size: 22px;
  line-height: 22px;
  text-transform: capitalize;
  color: #04092c;
  font-weight: 800;
  position: relative;
}
.pindrop_cssloader_main{
  position: absolute;
  width: 100%;
  height: 100%;
  top: 40px;
  left: 0;
  right: 0;
  background-color: transparent;
  z-index: 1;
  align-items: center;
  justify-content: center;
  display: flex;
}
.lds-ellipsis{
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
  z-index: 2;
}
.lds-ellipsis div{
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #f08100;
  -webkit-animation-timing-function: cubic-bezier(0,1,1,0);
  animation-timing-function: cubic-bezier(0,1,1,0);
}
.lds-ellipsis div:nth-child(1) {
  left: 8px;
  -webkit-animation: lds-ellipsis1 .6s infinite;
  animation: lds-ellipsis1 .6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;
  -webkit-animation: lds-ellipsis2 .6s infinite;
  animation: lds-ellipsis2 .6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 32px;
  -webkit-animation: lds-ellipsis2 .6s infinite;
  animation: lds-ellipsis2 .6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 56px;
  -webkit-animation: lds-ellipsis3 .6s infinite;
  animation: lds-ellipsis3 .6s infinite;
}
.performers-slide-item{
  padding: 10px 15px;
  border-radius: 6px;
  position: relative;
  background-color: rgb(245 161 0/20%);
  height: 120px;
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
}
.performers-slide-item:hover{
  background-color: rgb(245 161 0/35%);
}
.performer-slider-details {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.performers-slide-item .performer-name {
  font-size: 16px;
  color: #04092c;
  margin-bottom: 0px;
  text-transform: uppercase;
  letter-spacing: 0.4px;
  text-align: center;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.performer-slider-details .search-ticket-btn{
  margin: 15px 0 0 0;
  width: auto;
  padding: 6px 12px;
  font-size: 13px;
  line-height: 18px;
  background-color: rgb(240 129 0/70%);
  color: #fff;
  font-weight: 500;
  letter-spacing: 0.4px;
  border-radius: 6px;
  border: 0px;
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
}
.performer-slider-details .search-ticket-btn:hover {
  background-color: rgb(240 129 0);
}
.performers-listing-container{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
}
@media (min-width: 768px){
  .performer-slider-wrapper .section-header h2 {
    font-size: 27px;
    line-height: 42px;
    margin: 0 0 20px;
  }
}
@media (min-width: 1024px){
  .performer-slider-wrapper .section-header h2{
    font-size: 32px;
    line-height: 48px;
  }
  .innerpage-banner{
    height:180px
  }
}
@media (max-width: 1280px){
  .innerpage-banner .container{
    max-width:100%
  }
  .performer-slider-section{
    padding: 3% 8%;
  }
}
@media (max-width: 1024px){
  .performer-slider-section{
    padding: 30px 35px 40px;
  }
  .innerpage-banner .section-header h1{
    font-size:32px;
  }
}
@media (max-width: 991px){
  .performer-listing-wrap{
    padding: 20px;
  }
  .performer-listing-wrap a{
    width:100%;
    margin: 0 0 12px;
  }
  .no-records-found{
    font-size: 22px;
    line-height: 26px;
  }
}
@media (max-width: 768px) {
  .innerpage-banner .section-header h1{
    font-size:24px;
    margin-bottom: 5px;
  }
  .performer-title-wrapper{
    margin: 0 0 20px;
    padding: 0 0px 15px;
  }
}
@media (max-width: 767px){
  .performer-listing-wrap{
    padding: 20px 12px;
  }
  .performer-title-wrapper h2{
    font-size: 20px;
  }
  .performer-listing-wrap a{
    padding: 8px 10px;
  }
  .performer-listing-wrap a .performer-name{
    font-size:14px;
    line-height:18px;
    margin-bottom:4px
  }
  .performer-listing-wrap a .category-name{
    font-size:12px;
    line-height:18px
  }
  .innerpage-banner .container{
    max-width:100%
  }
  .search-ticket-btn{
    font-size:13px;
    padding:6px 8px;
  }
  .show-more-btn{
    padding: 10px 20px;
  }
  .performers-slide-item .performer-name{
    font-size: 14px;
  }
  .performer-slider-details .search-ticket-btn{
    font-size: 12px;
  }
}
@media (max-width: 575px) {
  .innerpage-banner .section-header h1{
    font-size: 22px;
  }
  .section-event-category.performer-category-wrap{
    padding: 35px 0;
  }
  .performer-header h2{
    margin-bottom:15px
  }
  .performer-header .search-wrapper{
    width:100%
  }
  .no-records-found{
    font-size: 18px;
  }
  .performer-nearest-evnets{
    margin-bottom: 25px;
  }
}
@media (max-width: 480px){
  .no-records-found{
    font-size: 16px;
  }
  .section-event-category.performer-category-wrap{
    padding: 30px 0;
  }
  .performer-listing-wrap .performers-list-item{
    flex-wrap: wrap;
  }
  .performer-title-wrapper h2{
    font-size: 18px;
  }
  .performer-slider-section{
    padding: 30px 10px 40px;
  }
  .section-event-category .section-content.performer-listing-wrap ul li {
    flex-direction: column;
  }
  .performer-list-name{
    /* width:100%;
    margin-bottom:16px */
    width: calc(100% - 110px);
  }
  .search-btn-wrap{
    /* display:flex; */
    margin-left:auto;
    width: 110px;
  }
}
@media (max-width: 400px){
  .innerpage-banner .section-header h1{
    font-size: 20px;
  }
  .performer-title-wrapper{
    padding: 0 0px 15px;
  }
}
@media (min-width:320px) and (max-width:915px) and (orientation:landscape){
  .innerpage-banner .section-header.performer-header h2{
    font-size: 28px;
    margin-bottom: 15px;
  }
  .performer-header h2{
    margin-bottom: 15px;
  }
}
</style>
<template>
  <section class="innerpage-banner">
    <!-- <v-lazy-image src="../../images/share-action.webp" alt="image" width="1920" height="235"></v-lazy-image> -->
    <div class="section-header performer-header">
      <div v-if="singleCatagory.length !== 0">
        <h1 v-for="(catagory, index) in singleCatagory" :key="index">
          {{ catagory.name }}
        </h1>
      </div>
      <h1 v-else>Select any performer to find tickets</h1>
      <div class="search-wrapper mobile-search-icon-j">
        <div class="hero-search header-search">
          <PerformerSearchAutocomplete
            ref="performerSearchInput"
          />
        </div>
      </div>
      <BannerSubText />
    </div>
  </section>
  <!-- <section class="performer-slider-section"> -->
    <!-- <div class="performer-slider-wrapper">
      <div class="section-header">
        <h2>Nearest performers</h2>
      </div>
      <div class="pindrop_cssloader_main" v-bind:class="{ 'dis-none': areNearestPerformersLoaded}">
          <div class="lds-ellipsis">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
      <div class="my-slider-2">
        <router-link :to="`/events/${item.slug}/${item.performerId}`" v-for="(item) in nearestPerformers" :key="item">
          <div class="performers-slide-item">
            <div class="performer-slider-details">
              <h4 class="performer-name">{{ item.name }}</h4>
              <button class="search-ticket-btn">Search Tickets</button>
            </div>
          </div>
        </router-link>
      </div>
    </div> -->
  <!-- </section> -->
  <section class="section-event-category category-wrap performer-category-wrap">
    <div class="container">
      <div class="section-content performer-listing-wrap performer-nearest-evnets" v-bind:class="{ 'mobile-dis-none': nearestPerformers.length <= 0 &&  notLoadingNearestPerformers}">
        <div class="performer-title-wrapper">
          <h2>Showing {{ nearestPerformers.length }} nearest Performers</h2>
        </div>
        <!-- <h1>Nearest Performers</h1> -->
        <div class="performer-row performers-listing-container" @scroll="onScroll">
          <router-link :to="`/events/${item.slug}/${item.performerId}`" v-for="(item) in nearestPerformers" :key="item">
            <div class="performers-list-item">
              <div class="performer-list-name">
                <h4 class="performer-name" href="javascript:void(0)">{{ item.name }}</h4>
                <span class="category-name" v-if="this.$route.params.categoryId">Event Type -  <span class="category-label" v-for="(catagory, index) in singleCatagory" :key="index">{{ catagory.name }}</span></span>
              </div>
              <div class="search-btn-wrap">
                <button type="button" class="search-ticket-btn">Search Tickets</button>
              </div>
            </div>
          </router-link>
          <div class="load-more-spinner" v-bind:class="{ 'dis-none': notLoadingNearestPerformers}">
            <i class="fa fa-circle-o-notch fa-spin"></i>
          </div>
          <p class="no-records-found" v-if="nearestPerformers.length <= 0 && notLoadingNearestPerformers">No Nearest performers found !</p>
          <div class="show-more-wrap" v-if="areAllNearestPerformersLoaded === false && nearestPerformers.length > 0">
            <!-- <button type="button" class="show-more-btn" v-on:click="getNearestPerformersByCat">Show More</button> -->
          </div>
        </div>
      </div>
      <div class="section-content performer-listing-wrap">
        <div class="performer-title-wrapper">
          <h2>Showing all {{ performers.length }} Performers</h2>
        </div>
        <div class="performer-row performers-listing-container" @scroll="onScroll">
          <router-link :to="`/events/${item.slug}/${item.performerId}`" v-for="(item) in performers" :key="item">
            <div class="performers-list-item">
              <div class="performer-list-name">
                <h4 class="performer-name" href="javascript:void(0)">{{ item.name }}</h4>
                <span class="category-name" v-if="this.$route.params.categoryId">Event Type -  <span class="category-label" v-for="(catagory, index) in singleCatagory" :key="index">{{ catagory.name }}</span></span>
              </div>
              <div class="search-btn-wrap">
                <button type="button" class="search-ticket-btn">Search Tickets</button>
              </div>
            </div>
          </router-link>
          <div class="load-more-spinner" v-bind:class="{ 'dis-none': notLoadingPerformers}">
            <i class="fa fa-circle-o-notch fa-spin"></i>
          </div>
          <p class="no-records-found" v-if="performers.length <= 0 && notLoadingPerformers">No performers found !</p>
          <div class="show-more-wrap" v-if="areAllPerformersLoaded === false && performers.length > 0">
            <button type="button" class="show-more-btn" v-on:click="getFarthestPerformersByCat('')">Show More</button>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from 'axios'
// import $ from 'jquery'
import PerformerSearchAutocomplete from '../../views/autocomplete/PerformerSearchAutocomplete'
import VLazyImage from 'v-lazy-image'
import BannerSubText from './../../components/layouts/BannerSubText.vue'
import Cookies from 'js-cookie'
// import { tns } from 'tiny-slider'
/* eslint-disable */
export default {
  name: 'Performers',
  components: {
    PerformerSearchAutocomplete,
    VLazyImage,
    BannerSubText
  },
  beforeCreate () {
    // var title = this.$route.params.categoryName ? this.$route.params.categoryName.replace(/(-)/g, ' ') : this.$route.query.q
    // title = title ? title.charAt(0).toUpperCase() + title.slice(1) : 'Performers'
    // document.title = title + ' | ' + process.env.VUE_APP_NAME
  },
  data: function () {
    return {
      performers: [],
      nearestPerformers: [],
      arePerformersLoaded: false,
      performerIDs: [],
      areNearestPerformersLoaded: false,
      assetsUrl: process.env.VUE_APP_ASSETS_URL,
      page: 1,
      nearestPage: 1,
      nearestLimit: 5,
      limit: 50,
      areAllPerformersLoaded: false,
      areAllNearestPerformersLoaded: false,
      notLoadingPerformers: false,
      notLoadingNearestPerformers: true,
      singleCatagory: [],
      singleCatagoryName: '',
      userSearchLocation: Cookies.get('userSearchLocation') ? JSON.parse(Cookies.get('userSearchLocation')) : '',
      userLocation: Cookies.get('userLocation') ? JSON.parse(Cookies.get('userLocation')) : '',
      latitude: '',
      longitude: '',
      userEmail: Cookies.get('userEmail'),
    }
  },
  watch: {
    $route (to, from) {
      this.performers = []
      this.arePerformersLoaded = false
      // this.getPerformers()
    }
  },
  mounted () {
    // var _learnq = _learnq || [];
    if (this.userSearchLocation) {
      this.latitude = this.userSearchLocation.lat
      this.longitude = this.userSearchLocation.lang
    } else {
      if (this.userLocation) {
        var location = this.userLocation.loc
        this.latitude = location.split(',')[0]
        this.longitude = location.split(',')[1]
      }
    }
    // this.getPerformers()
    // this.getNearestPerformers()
    this.getNearestPerformersByCat()
    if (this.$route.params.categoryId) {
      this.getSingleCategory()
    } else {
      document.title = 'Performers | ' + process.env.VUE_APP_NAME
    }
  },
  methods: {
    getSingleCategory: function () {
      axios.get(process.env.VUE_APP_API_URL + 'getSingleCategory', {
        params: {
          categoryId: this.$route.params.categoryId
        }
      }).then(response => {
        if (response.status === 200) {
          if (response.data.data.categories !== null) {
            this.singleCatagory.push(response.data.data.categories)
            this.singleCatagoryName = response.data.data.categories.name
            document.title = this.singleCatagoryName + ' | ' + process.env.VUE_APP_NAME
            this.klavioTracking(this.singleCatagoryName, this.$route.params.categoryId)
          } else {
            document.title = 'Performers | ' + process.env.VUE_APP_NAME
            this.klavioTracking("All Performers", "No Category")
          }
        }
      })
    },
    klavioTracking: function (catName, catID) {
      if(process.env.VUE_APP_KLAVIYO && this.userEmail){
        const options = {
        method: 'POST',
        headers: {accept: 'text/html', 'content-type': 'application/x-www-form-urlencoded'},
        body: new URLSearchParams({
          data: '{"token": "'+process.env.VUE_APP_KLAVIYO_CODE+'", "event": "viewed category", "customer_properties": {"$email": "'+ this.userEmail +'"}, "properties": {"item_name": "'+catName+'","$event_id": "'+catID+'"}}'
        })
      };
      fetch('https://a.klaviyo.com/api/track', options)
        .then(response => response.json())
        .then(response => response)
        .catch(err => err);
      }
    },
    getPerformers: function (event) {
      if (this.areAllPerformersLoaded === false) {
        this.notLoadingPerformers = false
        axios.get(process.env.VUE_APP_API_URL + 'getPerformers', {
          params: {
            page: this.page,
            limit: this.limit,
            lang: this.longitude,
            lat: this.latitude,
            categoryId: this.$route.params.categoryId,
            performerName: this.$refs.performerSearchInput.value || this.$route.query.performerName
          }
        }).then(response => {
          if (response.status === 200) {
            this.notLoadingPerformers = true
            this.page++
            this.arePerformersLoaded = true
            if (response.data.data.performers.length <= 0) {
              this.areAllPerformersLoaded = true
            } else {
              this.performers.push(...response.data.data.performers)
              if (response.data.data.performers.length < this.limit) {
                this.areAllPerformersLoaded = true
              }
            }
          }
        })
      }
    },
    getNearestPerformersByCat: function (event) {
      if (this.areAllNearestPerformersLoaded === false) {
        this.notLoadingNearestPerformers = false
        axios.get(process.env.VUE_APP_API_URL + 'getNearestPerformersByCat', {
          params: {
            // page: this.nearestPage,
            // limit: this.nearestLimit,
            lang: this.longitude,
            lat: this.latitude,
            categoryId: this.$route.params.categoryId,
            performerName: this.$refs.performerSearchInput.value || this.$route.query.performerName
          }
        }).then(response => {
          if (response.status === 200) {
            this.notLoadingNearestPerformers = true
            this.nearestPage++
            this.areNearestPerformersLoaded = true
            if (response.data.data.length <= 0) {
              this.areAllNearestPerformersLoaded = true
            } else {
              this.nearestPerformers.push(...response.data.data)
              this.performerIDs.push(...response.data.performerIDs)
              if (response.data.data.length < this.nearestLimit) {
                this.areAllNearestPerformersLoaded = true
              }
            }
            if (this.performerIDs) {
              this.getFarthestPerformersByCat(this.performerIDs)
            }
          }
        })
      }
    },
    getFarthestPerformersByCat: function (performerIDs) {
      if (performerIDs) {
        this.performerIDs = performerIDs
      }
      if (this.areAllPerformersLoaded === false) {
        this.notLoadingPerformers = false
        axios.get(process.env.VUE_APP_API_URL + 'getFarthestPerformersByCat', {
          params: {
            page: this.page,
            limit: this.limit,
            categoryId: this.$route.params.categoryId,
            performerIDs: this.performerIDs,
            performerName: this.$refs.performerSearchInput.value || this.$route.query.performerName
          }
        }).then(response => {
          if (response.status === 200) {
            this.notLoadingPerformers = true
            this.page++
            this.arePerformersLoaded = true
            if (response.data.data.performers.length <= 0) {
              this.areAllPerformersLoaded = true
            } else {
              this.performers.push(...response.data.data.performers)
              if (response.data.data.performers.length < this.limit) {
                this.areAllPerformersLoaded = true
              }
            }
          }
        })
      }
    },
    // getNearestPerformers: function (event) {
    //   this.notLoadingPerformers = false
    //   axios.get(process.env.VUE_APP_API_URL + 'getNearestPerformers', {
    //     params: {
    //       lang: this.longitude,
    //       lat: this.latitude,
    //       categoryId: this.$route.params.categoryId
    //     }
    //   }).then(response => {
    //     if (response.status === 200) {
    //       this.notLoadingPerformers = true
    //       this.nearestPerformers = response.data.data
    //     }
    //   }).then(response => {
    //     this.areNearestPerformersLoaded = true
    //     var slider2 = tns({
    //       autoplay: true,
    //       container: '.my-slider-2',
    //       items: 1,
    //       slideBy: 1,
    //       speed: 300,
    //       nav: false,
    //       gutter: 20,
    //       controls: true,
    //       mouseDrag: true,
    //       controlsText: ['<span class="fa fa-chevron-left"></span>', '<span class="fa fa-chevron-right"></span>'],
    //       responsive: {
    //         481: {
    //           items: 2,
    //           slideBy: 2,
    //           gutter: 10,
    //           speed: 400
    //         },
    //         992: {
    //           items: 3,
    //           slideBy: 3,
    //           gutter: 10,
    //           speed: 600
    //         },
    //         1200: {
    //           items: 4,
    //           slideBy: 4,
    //           speed: 600
    //         }
    //       }
    //     })
    //   })
    // },
    searchPerformers: function (event) {
      if (this.$refs.performerSearchInput.value) {
        // window.location = window.location.origin + '/performers?q=' + this.$refs.performerSearchInput.value
        window.location = window.location.origin + '/performers/' + this.$route.params.categoryName + '/' + this.$route.params.categoryId + '?performerName=' + this.$refs.performerSearchInput.value
        /* this.$router.push({
          params: { categoryName: this.$route.params.categoryName, categoryId: this.$route.params.categoryId },
          query: { q: this.$refs.performerSearchInput.value }
        }) */
      }
    }
  }
}
</script>
