<template>
  <div class="performer-search-autocomplete global-search-autocomplete">
    <input
      id="performerSearchInput"
      type="text"
      @input="onChange"
      v-model="performersSearch"
      @keydown.down="onArrowDown"
      @keydown.up="onArrowUp"
      @keydown.enter="onEnter"
      class="header-auto-search"
      ref="performerSearchInput"
      placeholder="Search Performers"
    />
    <ul id="autocomplete-results" v-show="isOpen" class="autocomplete-results">
      <span v-if="results.performers" class="show-result"><a href="javascript:void(0);" v-on:click="showAllResults">Show all events matching "{{ this.$refs.performerSearchInput.value }}"</a></span>
      <li class="loading autocomplete-loader" v-if="isLoading">
        <i class="fa fa-circle-o-notch fa-spin"></i>
      </li>
      <a :href="`/events/${result.name}/${result.performerId}`"
        v-for="(result, i) in results.performers"
        :key="i"
        @click="setResult(result)"
        class="performers-listing"
        :class="{ 'is-active': i === arrowCounter }"
      >
       <span class="full-date">{{ result.name }}</span>
      </a>
    </ul>
  </div>
</template>

<script>
/* eslint-disable */
import axios from 'axios'
// import moment from 'moment'
// import $ from 'jquery'

export default {
  name: "PerformerSearchAutocomplete",
  props: {
    items: {
      type: Array,
      required: false,
      default: () => []
    },
    isAsync: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      isOpen: false,
      results: [],
      search: this.$route.query.q,
      isLoading: false,
      arrowCounter: -1,
      performerSearchPlaceholder: 'Search performers',
      isGlobalSearchNotInFocus: true,
      debounce:false
    };
  },
  watch: {
    items: function(value, oldValue) {
      if (value.length !== oldValue.length) {
        this.results = value;
        this.isLoading = false;
      }
    }
  },
  mounted() {
    document.addEventListener("click", this.handleClickOutside);
  },
  destroyed() {
    document.removeEventListener("click", this.handleClickOutside);
  },
  methods: {
    blurBackground: function (e) {
      this.isGlobalSearchNotInFocus = false
      document.body.classList.add("global-search-blur");
      document.getElementById("performerSearchInput").setAttribute("placeholder", "");
      // $('body').addClass('global-search-blur')
      // $('#performerSearchInput').attr('placeholder', '')
    },
    removeBlurFromBody: function (e) {
      this.isGlobalSearchNotInFocus = true
      document.body.classList.remove("global-search-blur");
      document.getElementById("performerSearchInput").setAttribute("placeholder", this.performerSearchPlaceholder);
      // $('body').removeClass('global-search-blur')
      // $('#performerSearchInput').attr('placeholder', this.performerSearchPlaceholder)
    },
    showAllResults: function (e) {
      this.$router.push({
        path: '/events',
        query: { q: this.$refs.performerSearchInput.value }
      })
    },
    setResult(result) {
      this.search = result.name
      this.isOpen = false;
    },
    filterResults() {
      this.isLoading = true;
      this.results = [];
      axios.get(process.env.VUE_APP_API_URL + 'getPerformers', {
        params: {
          performerName: this.$refs.performerSearchInput.value,
          categoryId: this.$route.params.categoryId
        }
      }).then(response => {
        this.isLoading = false;
        this.results = response.data.data
      })
    },
    onChange() {
      this.$emit("input", this.search);
      if (! this.isAsync) {
        clearTimeout(this.debounce)
          this.debounce = setTimeout(() => {
          this.filterResults();
        }, 300)
        // this.filterResults();
        this.isOpen = true;
      }
    },
    handleClickOutside(event) {
      if (!this.$el.contains(event.target)) {
        this.isOpen = false;
        this.arrowCounter = -1;
      }
    },
    onArrowDown() {
      if (this.arrowCounter < this.results.length) {
        this.arrowCounter = this.arrowCounter + 1;
      }
    },
    onArrowUp() {
      if (this.arrowCounter > 0) {
        this.arrowCounter = this.arrowCounter - 1;
      }
    },
    onEnter() {
      // window.location.href = process.env.BASE_URL + 'events?q=' + this.$refs.performerSearchInput.value
      window.location = window.location.origin + '/performers/' + this.$route.params.categoryName + '/' + this.$route.params.categoryId + '?performerName=' + this.$refs.performerSearchInput.value
      this.showAllResults()
    }
  }
};
</script>

<style>
.autocomplete {
  position: relative;
}

.autocomplete-results {
  padding: 0;
  margin: 0;
  border: 1px solid #eeeeee;
  height: 120px;
  overflow: auto;
}

.autocomplete-result {
  list-style: none;
  text-align: left;
  padding: 4px 2px;
  cursor: pointer;
}

.autocomplete-result.is-active,
.autocomplete-result:hover {
  background-color: #4aae9b;
  color: white;
}
</style>